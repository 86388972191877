html {font-size: 125%;} /*20px*/

// Generic

.doc-sidebar {
  width: auto;
  max-width: 250px;
  padding-top: 5px; 
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 120px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: row;
    position: static;
    margin-bottom: 20px;
  }
}

.back-button {
  opacity: 1;
  text-decoration: none;
  padding: 2px 11px 4px 12px;
  margin: 3px 0;
  border-radius: 4px;

  &:hover {
    opacity: 1;
    background-color: #e6e6e6;
  }
}

.generic-documentation {
  width: 100%;
  max-width: 800px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 700px;
    padding: 30px 20px !important;
  }
}

.teaching-home-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 800px;
}


.teaching-card-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.teaching-card {
  width: 30%;
  border-radius: 10px;
  border: solid 2px rgba(0,0,0,0.2);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &.stretch {
    flex: 1;
  }
  
  .card-title {
    font-size: 38px;
    margin: 0;
  }

  &:hover {
    opacity: 1;
    border: solid 2px rgb(64, 108, 250);
  }
}


.documentation-body {
  background-color: white !important;
  color: $text-color;
}

//other

.documentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: 1000px;
  margin: 0 auto;
  color: #2a2d33;
  width: 100%;
  padding: 120px 20px;  
  font-size: 20px;
  line-height: 1.45;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 760px;
    padding: 60px 20px;
  }

  p {margin-bottom: 1em;}

  h1, h2, h3, h4, h5 {
    margin: 3rem 0 1rem;
    font-weight: bold;
    line-height: 1.15;
  }

  h1 {
    margin-top: 0;
    font-size: 3.052em;
    & + h5 {
      margin: 0.75rem 0 1rem;
    }
  }

  h2 {font-size: 2.441em;}

  h3 {font-size: 1.953em;}

  h4 {
    font-size: 1.563em;

    & + h6 {
      margin: 2rem 0 0.5em;
    }
  }

  h5 {font-size: 1.25em;}

  h6 {
    font-size: 0.8em;
    opacity: 0.6;
    margin: 3rem 0 0.5em;

    & + h4 {
      margin: 1rem 0 1rem;
    }
  }

  small, .text-small {font-size: 0.8em;}

  .grey-text {
    opacity: 0.6;
  }
  .no-margin {
    margin: 0;
  }

  blockquote {
    p {margin: 0 0 5px;}
    opacity: 0.6;
    padding-left: 15px;
    border-left: solid 10px #e3e3e3;
  }

  ul {
    padding-left: 22px;
  }

  ul + p {
    margin: 20px 0 20px;
  }

  p + ul {
    margin: 0px 0 20px;
  }
}


.subject-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 800px;
}

$blue: #406cfa;

.sujet-link-wrapper {
  align-self: stretch;
  margin: 10px 0;
  border-radius: 10px;
  border: solid 2px rgba(0,0,0,0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    opacity: 1;
    border: solid 2px $blue;
  }
}

.sujet-num {
  font-size: 0.8em;
  opacity: 0.6;
   &.spaced {
    margin-bottom: 20px
   }
}

.sujet-link-title {
  font-size: 1.563em;
  font-weight: bold;
}

.date-note {
  display: flex;
  justify-content: space-between;
}

.notes-link {
  a {
    font-weight: bold;
  }
  font-weight: bold;
  margin-bottom: 20px;
}

.sujet-title {
  font-size: 1.953em;
  font-weight: bold;
  margin: 10px 0 5px;
}

.sujet-subtitle {
  font-size: 1.25em;
  font-weight: bold;
  margin: 5px 0 30px;
}

.mgb-s {
  margin-bottom: 20px;
}


.menu-divider {
  display: block;
  width: 15%;
  height: 1px;
  background-color: #dfdfdf;
  margin: 10px 0px;

  @media screen and (max-width: 1000px) {
    margin: 0px 10px;
    width: 1px;
    height: 42px;
  }
}

.doc-menu {
  min-width: 250px;
  width: 250px;
  padding-top: 5px; 
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 120px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: row;
    position: static;
    margin-bottom: 40px;
  }
}


.doc-header {
  border-radius: 10px;
  // background-color: rgba(0, 0, 0, 0.05);
  // background-color: rgba(64, 108, 250, 0.05);
  display: flex;
  align-items: flex-start;
  // padding: 40px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  align-self: center;
  position: relative;
}

.header-side-section {
  // width: 25%;
  display: flex;
  
  &.end {
    justify-content: flex-end;
  }
}

.header-main {
  flex: 1;
  // padding-left: 20px;
  // text-align: center;
}


.header-title {
  margin-top: -6px !important;

}
.header-subtitle {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}

.header-btn {
  position: absolute;
  left: -60px;
  top: 0;

  @media screen and (max-width: 920px) {
    position: static;
    margin-right: 20px;
  }
}



.header-btn, .header-drive-link {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.1);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding-bottom: 2px;
  align-self: center;

  &:hover {
    opacity: 1;
    background-color: $blue;
    color: white;
  }
}

.header-drive-link {
  width: auto;
  padding: 0px 14px;
  font-size: inherit;
}


.doc-menu-link {
  opacity: 1;
  text-decoration: none;
  padding: 2px 10px 3px 15px;
  margin: 3px 0;
  border-radius: 4px;

  &:hover {
    opacity: 1;
    background-color: #e6e6e6;
  }

  &.active {
    border-left: solid 10px #f4f4f4;
  }

  &.external span {
    display: inline-block;
    transform: translateY(-1px);
  }

  @media screen and (max-width: 1000px) {
    margin: 0px 5px;
    &:first-child {
      margin: 0px 5px 0px 0px ;
    }
    &:last-child {
      margin: 0px 0px 0px 5px;
    }
  }
}

.doc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subject-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  max-width: 800px;
}

.sujet-date-rendu {
  margin-bottom: 20px;
  padding-left: 20px;
  border-left: solid 5px rgba(0,0,0,0.1);
}