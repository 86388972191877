@font-face {
  font-family: 'GTCinetype';
  src: url('fonts/GTCinetypeRegular.eot');
  src: url('fonts/GTCinetypeRegular.woff2') format('woff2'),
       url('fonts/GTCinetypeRegular.woff') format('woff'),
       url('fonts/GTCinetypeRegular.ttf') format('truetype'),
       url('fonts/GTCinetypeRegular.svg#GTCinetypeRegular') format('svg'),
       url('fonts/GTCinetypeRegular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTCinetype';
  src: url('fonts/GTCinetypeRegular-Italic.eot');
  src: url('fonts/GTCinetypeRegular-Italic.woff2') format('woff2'),
       url('fonts/GTCinetypeRegular-Italic.woff') format('woff'),
       url('fonts/GTCinetypeRegular-Italic.ttf') format('truetype'),
       url('fonts/GTCinetypeRegular-Italic.svg#GTCinetypeRegular-Italic') format('svg'),
       url('fonts/GTCinetypeRegular-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GTCinetype';
  src: url('fonts/GTCinetypeBold.eot');
  src: url('fonts/GTCinetypeBold.woff2') format('woff2'),
       url('fonts/GTCinetypeBold.woff') format('woff'),
       url('fonts/GTCinetypeBold.ttf') format('truetype'),
       url('fonts/GTCinetypeBold.svg#GTCinetypeBold') format('svg'),
       url('fonts/GTCinetypeBold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GTCinetype';
  src: url('fonts/GTCinetypeBold-Italic.eot');
  src: url('fonts/GTCinetypeBold-Italic.woff2') format('woff2'),
       url('fonts/GTCinetypeBold-Italic.woff') format('woff'),
       url('fonts/GTCinetypeBold-Italic.ttf') format('truetype'),
       url('fonts/GTCinetypeBold-Italic.svg#GTCinetypeBold-Italic') format('svg'),
       url('fonts/GTCinetypeBold-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: italic;
}
