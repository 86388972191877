* {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6,
p, a, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  font-family: 'GTCinetype', Helvetica, Arial, sans-serif;
  line-height: 1.7;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;

  // @media screen and (max-width: 700px) {
  //   font-size: 18px;
  // }

  @media screen and (max-width: 850px) {
    font-size: 18px;
  }

}

$grey-dark: #232425;
$grey-medium: rgba(125, 126, 138, 0.8);
$grey-light: rgba(125, 126, 138, 0.45);
$grey-xlight: rgba(125, 126, 138, 0.15);

$text-color: $grey-dark;
$border-color: $grey-light;

body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;

  &.animated {
    transition: color 0.2s, background-color 0.2s;
  }

  &.light {
    color: $text-color;
    background-color: white;
  }

  &.dark {
    color: white;
    background-color: $text-color;
  }

  &.lock-scroll {
    overflow: hidden;
  }
}


.page-wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 80px 80px;

  @media screen and (max-width: 1520px) {
    max-width: 1300px;
  }

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }

  @media screen and (max-width: 850px) {
    padding: 40px 20px;
  }
}

.content-wrapper {
  
  width: 100%;
  max-width: 640px;

  // @media screen and (max-width: 700px) {
  //   padding: 60px 40px;
    
  // }
}

.content-wrapper-portfolio {
  width: 100%;
}




.animated a {
  transition: color 0.2s;
}

.light a, .light a:visited {
  color: $text-color;
}

.dark a, .dark a:visited {
  color: white;
}

a, a:visited {
  color: $text-color;
  text-decoration: underline;

  &:hover {
    opacity: 0.6;
  }
}


.theme-toggler {
  display: inline-flex;
  height: 34px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  padding: 0px 8px;
  border-radius: 4px;
  border: solid 2px $border-color;
  flex-wrap: nowrap;
  white-space: nowrap;

  div {
    white-space: nowrap;
  }

}

.theme-toggler .indicator {
  display: inline-block;
  position: relative;
  margin-left: 5px;
  transform: translateY(17px);
  // flex: 1;

  @media screen and (max-width: 850px) {
    margin-left: 0px;
  }
  

  .light, .dark {
    // position: absolute;
    // height: 100%;
    // top: 0;
    //left: 5px;

    // opacity: 0;
  }

  .light {
    top: 0;
  }

  .dark {
    top: 34px;
  }
}

.indicator-text {
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.animated {
  // opacity: 1;
  .indicator {
    transition: transform 0.2s;

    .light, .dark {
      transition: opacity 0.2s;
    }
  }
}

body.light {
  .indicator {
    // opacity: 1;
    transform: translateY(-17px)
  }
  
  .indicator .dark {
    opacity: 1;
  }
}

body.dark {
  .indicator .light {
    opacity: 1;
  }
}



.grey-text {
  color: $grey-medium;
}

.alune-header-wrapper {
  border-bottom: solid 2px $border-color;
  padding-bottom: 60px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }

}

.alune-header {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: stretch;
  }

  
  // margin-bottom: 30px;
}

.profile-pic-wrapper {
  border-radius: 2000px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin-right: 20px;

  @media screen and (max-width: 1100px) {
    width: 70px;
    height: 70px;
  }
}

.header-profile-pic {
  display: block;
  width: 100%;
  height: 100%;
  
}

.header-text {
  @media screen and (max-width: 1100px) {
    flex: 1;
  }
}

.header-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 850px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.menu-button-wrapper {
  @media screen and (max-width: 1100px) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}

.menu-button {
  display: none;
  
  @media screen and (max-width: 1100px) {
    display: block;
    cursor: pointer;
    background-color: $grey-light;
    padding: 2px 8px;
    border-radius: 8px;
  }
}


.sidebar {
  width: 300px;

  .sidebar-content-wrapper {
    display: flex;
    flex-direction: column;  
    align-items: flex-end;
    position: sticky;
    top: 80px;

    @media screen and (max-width: 1100px) {
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      position: relative;
      top: 0;
    }

    .menu-button {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 1100px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 80px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-300px);
    transition: opacity 0.3s, transform 0.3s;
  }

  @media screen and (max-width: 850px) {
    padding: 40px 20px;
  }
}

.menu-opened .sidebar {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
}

@media screen and (max-width: 1100px) {
  .content-wrapper {
    transition: opacity 0.3s, transform 0.3s;
  }
}

.menu-opened .content-wrapper {
  opacity: 0;
  transform: translateY(300px);
} 

.alune-header-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  a {
    margin-right: 4px;
  }
}


.portfolio-back-button {
  text-decoration: none;
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  border-radius: 7px;
  background-color: $grey-xlight;
  padding: 2px 10px;

  &:hover {
    background-color: $grey-light;
    opacity: 1;
  }
}


///////// Hints on email copy 
.hint-wrapper {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.email-copy {
  cursor: pointer;

  &:before {
    content: "copy ";
    color: $grey-light;
    opacity: 0;
    transition: opacity 0.2s;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  &:hover:before {
    opacity: 1;
  }
}

.email-hint {
  position: fixed;
  top: 30px;
  padding: 2px 15px;
  border-radius: 300px;
  transform: translateY(-160px);
  transition: transform 0.3s;
  text-align: center;

  &.copy-hint {
    transform: translateY(0);
  }
}

.light .email-hint {
  background-color: $grey-dark;
  color: white;
}

.dark .email-hint {
  background-color: white;
  color: $text-color;
}



.home-project-entry {
  h1, h2, h3, h4, h5, h6,
  p, a, blockquote, pre, hr,
  dl, dd, ol, ul, figure {
    font-size: 20px;

    @media screen and (max-width: 850px) {
      font-size: 18px;
    }
  }

  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  margin-bottom: 100px;

  @media screen and (max-width: 1100px) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 850px) {
    margin-bottom: 60px;
    
    &:hover:after {
      display: none;
    }
  }

  &:hover {
    opacity: 1;
  }

  @media screen and (min-width: 850px) {
    &:hover:after {
      content: "";
      position: absolute;
      z-index: 0;
      display: block;
      top: -20px;
      right: -20px;
      bottom: -20px;
      left: -20px;
      border-radius: 30px;
      background-color: $grey-xlight;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  .project-header, .project-main-img {
    position: relative;
    z-index: 1;
  }
}

.mobile-details-link {
  display: none;
  
  @media screen and (max-width: 850px) {
    display: inline-flex;
    align-items: center;
    align-self: flex-end;
    background-color: $grey-xlight;
    border-radius: 7px;
    padding: 2px 10px;
    margin-top: 20px;
  }
}



.project-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  .desc {
    width: 66%;
    flex-shrink: 0;
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: stretch;

    .desc {
      width: 100%;
    }
  }
}

.project-main-img {
  border-radius: 15px;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }
}

.project-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  margin-left: -3px;
}

.project-tag {
  padding: 0px 10px;
  border-radius: 100px;
  background-color: $grey-xlight;
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.project-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .img-wrapper {
    margin-bottom: 2%;
    border-radius: 15px;
    overflow: hidden;

    &.border {
      border: solid 2px $grey-xlight;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .img-100 {
    width: 100%;
  }

  .img-50 {
    width: 49%;

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  p {
    width: 66.666%;
    margin-left: 33.333%;
    margin-bottom: 40px;

    @media screen and (max-width: 850px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .img-wrapper + p {
    margin-top: 20px;
  }

  
}

.video-wrapper {
  margin-bottom: 2%;
  border-radius: 15px;
  overflow: hidden;

  video {
    display: block;
    width: 100%;
  }
}

.project-page-description {
  display: flex;
  flex: 1;
  width: 100%;
  margin: 20px 0 40px 0;

  h1, h2, h3, h4, h5, h6,
  p, a, blockquote, pre, hr,
  dl, dd, ol, ul, figure {
    font-size: 20px;

    @media screen and (max-width: 850px) {
      font-size: 18px;
    }
  }

  .w33 {
    width: 33.333%; 
  }
  .w66 {
    width: 66.666%;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;

    .w33 {
      width: 100%; 
    }
    .w66 {
      width: 100%;
    }
  }
}